import axios from 'axios'
import { Auth } from 'aws-amplify';
import { sleep } from './index'

export default {
    async axiosInstance() {
        var token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
        var instance = axios.create({
            baseURL: process.env.VUE_APP_API_ENDPOINT,
            timeout: 30000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return instance
    },
    async pollingGet(url_path, retries = 30, delay = 5) {
        const instance = await this.axiosInstance();
        for (; retries > 0; retries--) {
          const response = await instance.get(url_path);
          if (response.status == 202) {
            await sleep(delay * 1000);
          } else if (response.status == 200) {
            return response;
          }
        }
        return null;
    },
}