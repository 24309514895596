const sleep = ms => new Promise(r => setTimeout(r, ms));
const tsToDateString = timestamp => {
    if (timestamp === undefined) return "";
    if (timestamp < 999999999999) {
        timestamp = timestamp * 1000;
    }
    return new Date(timestamp).toLocaleDateString('en-CA');
}
const dateToDateString = date => date.toLocaleDateString('en-CA');
const tsToISOString = timestamp => {
    if (timestamp === undefined) return "";
    if (timestamp < 999999999999) {
        timestamp = timestamp * 1000;
    }
    return new Date(timestamp).toISOString();
}

export {
    sleep,
    tsToDateString,
    dateToDateString,
    tsToISOString,
}